.container {
  display: flex;
  align-items: flex-start;
  
  > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media (max-width: 1024px) {
      justify-content: flex-start;
      padding: 6rem 1.5rem 0;
    }
  }

  > aside {
    width: 22rem;
    height: 100vh;
    background: var(--blue-500);

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

